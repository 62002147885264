import Box from "@/elements/Box";
import Button from "@/elements/Button";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  CentraleDto,
  getGetCentraleQueryKey,
  ReminderDto,
  ReminderType,
  useUpdateReminders,
} from "@/api";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import Table from "@/elements/Table";
import * as React from "react";
import { useMemo } from "react";
import { useDbRelationsUpdateForm } from "@/utils/useDbRelationsUpdate";
import { withOpeningReset } from "../../utils/withOpeningReset";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { Controller } from "react-hook-form";
import { Role, roles } from "@/utils/rolesAndPermissions";
import {
  getReminderTypeHelper,
  reminderTypes,
} from "@/utils/reminderTypeUtils";
import { sortBy } from "lodash";

const WrappedReminderTypes: { type: ReminderType }[] = reminderTypes.map(
  (type) => ({ type: type as ReminderType })
);

const columnHelper =
  createColumnHelper<(typeof WrappedReminderTypes)[number]>();

function GestisciReminderDialog({
  open = false,
  onClose,
  centrale,
}: {
  open?: boolean;
  onClose?: () => void;
  centrale: CentraleDto;
}) {
  const handleClose = () => {
    onClose?.();
  };

  const reminders = useMemo(() => {
    if (!centrale.reminders) {
      return null;
    }

    return sortBy(centrale.reminders, "deltaDays");
  }, [centrale]);

  const {
    data,
    control,
    handleSubmit,
    getChangeSubmitData,
    getFieldName,
    formState: { errors },
    create,
    remove,
    restore,
    getEntityState,
  } = useDbRelationsUpdateForm(reminders, { deleteChangeType: "deleted" });

  console.log("data", data);

  const columns = useMemo<
    ColumnDef<(typeof WrappedReminderTypes)[number], any>[]
  >(() => {
    const deltaDaysCell = (role: Role) => {
      return ({
        row: {
          original: { type },
        },
      }: CellContext<(typeof WrappedReminderTypes)[number], any>) => {
        //const data = getChangeSubmitData();
        return (
          <ReminderTableCell
            data={data}
            role={role}
            type={type}
            getEntityState={getEntityState}
            getFieldName={getFieldName}
            control={control}
            remove={remove}
            restore={restore}
            create={create}
          />
        );
      };
    };

    const columns = [
      columnHelper.accessor((r) => getReminderTypeHelper(r.type)?.label, {
        id: "reminderType",
        header: "Reminder",
      }),

      ...roles.map((role) => {
        return columnHelper.display({
          header: role,
          cell: deltaDaysCell(role),
        });
      }),

      // columnHelper.accessor("daysBeforeForAzienda", {
      //   header: "Azienda",
      //   cell: daysBeforeCell("daysBeforeForAzienda"),
      // }),
      // columnHelper.accessor("daysBeforeForTecnici", {
      //   header: "Tecnici",
      //   cell: daysBeforeCell("daysBeforeForTecnici"),
      // }),
      // columnHelper.accessor("daysBeforeForLaboratorio", {
      //   header: "Laboratorio",
      //   cell: daysBeforeCell("daysBeforeForLaboratorio"),
      // }),
      // columnHelper.accessor("daysBeforeForCommerciali", {
      //   header: "Commerciali",
      //   cell: daysBeforeCell("daysBeforeForCommerciali"),
      // }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [control, getFieldName, data, getEntityState]);

  const { mutateAsync, isLoading, error } = useUpdateReminders({
    mutation: {
      onSuccess: () => {
        toast.success("Impostazioni reminder salvate!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const onSubmit = async () => {
    try {
      await mutateAsync({
        id: centrale.id,
        data: getChangeSubmitData(),
      });
      await queryClient.invalidateQueries({
        queryKey: getGetCentraleQueryKey(centrale.id),
      });
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>Gestione reminder - {centrale.nome}</DialogTitle>
      <DialogContent>
        {reminders && (
          <Box>
            {errors.root?.message && (
              <Alert sx={{ mt: 1 }} severity="warning">
                {errors.root?.message}
              </Alert>
            )}

            <Table
              columns={columns}
              data={WrappedReminderTypes}
              sortable={false}
              paginated={false}
              initialSort={[{ id: "reminderType", desc: true }]}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}

        <Button
          size="small"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          Salva
        </Button>

        {onClose && (
          <Button size="small" color="light" onClick={handleClose}>
            Chiudi
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(GestisciReminderDialog);

function ReminderTableCell({
  data,
  role,
  type,
  getEntityState,
  getFieldName,
  control,
  remove,
  restore,
  create,
}: {
  data: ReminderDto[];
  role: Role;
  type: ReminderType;
  getEntityState: (index: number) => string;
  getFieldName: (index: number, key: keyof ReminderDto) => string;
  control: any;
  remove: any;
  restore: any;
  create: any;
}) {
  const _reminders = data.filter(
    (r) => r.role === role && r.reminderType === type
  );
  const indexes = _reminders.map((r) => data.indexOf(r));
  const labels = ["Primo reminder", "Secondo reminder"];

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      {labels.map((label, _i) => {
        if (indexes.length > _i) {
          const reminderIndex = indexes[_i];
          const isDeleted = getEntityState(reminderIndex) === "deleted";

          return (
            <Controller
              key={label}
              name={getFieldName(reminderIndex, "deltaDays")}
              control={control}
              render={({ field }) => (
                <DaysSelect
                  label={label}
                  value={
                    getEntityState(reminderIndex) === "deleted"
                      ? null
                      : field.value
                  }
                  onChange={(value) => {
                    if (value === null) {
                      remove(reminderIndex);
                    } else {
                      if (isDeleted) {
                        restore(reminderIndex);
                      }
                      field.onChange(value);
                    }
                  }}
                />
              )}
            />
          );
        } else {
          return (
            <DaysSelect
              label={label}
              key={label}
              value={null}
              onChange={(value) => {
                if (value) {
                  create({
                    deltaDays: value,
                    role,
                    reminderType: type,
                  });
                }
              }}
            />
          );
        }
      })}
    </Box>
  );
}

function DaysSelect({
  label,
  value,
  onChange,
}: {
  label: string;
  value: number | null;
  onChange: (v: number | null) => void;
}) {
  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value ?? "off"}
        onChange={(e) => {
          if (e.target.value === "off") {
            onChange(null);
          } else {
            const value =
              typeof e.target.value === "number"
                ? e.target.value
                : parseInt(e.target.value);
            onChange(value);
          }
        }}
        size="small"
        sx={{
          minWidth: 120,
          fontSize: "0.75em !important",
          "& .MuiSelect-select": { padding: "0.5em !important" },
        }}
      >
        <MenuItem value="off">Disattivato</MenuItem>
        <Divider />
        <MenuItem value={-1}>1 giorno prima</MenuItem>
        <MenuItem value={-5}>5 giorni prima</MenuItem>
        <MenuItem value={-10}>10 giorni prima</MenuItem>
        <MenuItem value={-15}>15 giorni prima</MenuItem>
        <MenuItem value={-30}>30 giorni prima</MenuItem>
        <Divider />
        <MenuItem value={1}>1 giorno dopo</MenuItem>
        <MenuItem value={5}>5 giorni dopo</MenuItem>
        <MenuItem value={10}>10 giorni dopo</MenuItem>
        <MenuItem value={15}>15 giorni dopo</MenuItem>
        <MenuItem value={30}>30 giorni dopo</MenuItem>
      </Select>
    </Box>
  );
}

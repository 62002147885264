import Card from "@mui/material/Card";
import {
  CentraleDto,
  CentraleImmagineDto,
  getDownloadCentraleImmagineQueryKey,
  getDownloadFileQueryKey,
  getGetCentraleQueryKey,
  useUpdateImmagini,
  useUploadFile,
} from "@/api";
import { Fragment, useMemo } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@/context/useAuth";
import Image from "../Images/Image";
import ImageAdd from "../Images/ImageAdd";

export default function CentraleImmaginiCard({
  centrale,
}: {
  centrale: CentraleDto;
}) {
  const { hasPermission } = useAuth();

  const addImages = useUpdateImmagini({
    mutation: {
      onSuccess: () => {
        toast.success("Immagine salvata!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const deleteImages = useUpdateImmagini({
    mutation: {
      onSuccess: () => {
        toast.success("Immagine eliminata!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const { mutateAsync } = useUploadFile();

  const queryClient = useQueryClient();

  const immaginiVuote = useMemo(() => {
    const arr = [];
    if (centrale.centraleImmagini) {
      for (let i = 0; i < 2 - centrale.centraleImmagini.length; i++) {
        arr.push(i);
      }
    }
    return arr;
  }, [centrale]);

  const handleImageUpload = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        let result = await mutateAsync({
          data: { file },
        });
        const result1 = await addImages.mutateAsync({
          id: centrale.id!,
          data: [
            {
              changeType: "added",
              entity: { uploadedFileId: result.data.id },
            },
          ],
        });
        queryClient.invalidateQueries({
          queryKey: getGetCentraleQueryKey(centrale.id),
        });
      } catch (error: any) {
        handleUploadError(error.status);
      }
    }
  };

  const handleImageDelete = async (immagine: CentraleImmagineDto) => {
    if (window.confirm("sei sicuro di voler eliminare l'immagine?")) {
      await deleteImages.mutateAsync({
        id: centrale.id!,
        data: [
          {
            id: immagine.id,
            changeType: "deleted",
            entity: {
              uploadedFileId: immagine.id!,
            },
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: getGetCentraleQueryKey(centrale.id),
      });
    }
  };

  function handleUploadError(status?: number | undefined) {
    const msg =
      status === 413
        ? "Errore: il file è troppo grande"
        : "Errore durante il caricamento del file";
    toast.error(msg);
  }

  if (hasPermission("CentraleUpdateImmagini")) {
    return (
      <Card
        sx={{
          minWidth: {
            xs: "100%",
          },
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          padding: 2,
          gap: 2,
        }}
      >
        {centrale.centraleImmagini?.map(
          (immagine: CentraleImmagineDto, i: number) => {
            const saved = !!immagine.id;
            const justUploaded = !!immagine.uploadedFileId;

            const url = saved
              ? getDownloadCentraleImmagineQueryKey(
                  centrale.id,
                  immagine.id!
                )[0]
              : justUploaded
              ? getDownloadFileQueryKey(immagine.uploadedFileId!)[0]
              : null;

            if (!url) {
              return <Fragment key={immagine.id} />;
            }

            return (
              <Image
                key={immagine.id ?? i}
                src={url}
                alt={"immagine " + (i + 1)}
                handleImageDelete={() => handleImageDelete(immagine)}
              />
            );
          }
        )}
        {immaginiVuote.map((_, i) => (
          <ImageAdd key={i} handleImageUpload={handleImageUpload} />
        ))}
      </Card>
    );
  } else {
    if (centrale.centraleImmagini && centrale.centraleImmagini.length > 0) {
      return (
        <Card
          sx={{
            minWidth: {
              xs: "100%",
            },
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            padding: 2,
            gap: 2,
          }}
        >
          {centrale.centraleImmagini?.map(
            (immagine: CentraleImmagineDto, i: number) => (
              <Image
                key={immagine.id}
                src={
                  getDownloadCentraleImmagineQueryKey(
                    centrale.id,
                    immagine.id!
                  )[0]
                }
                alt={"immagine " + i}
                canDelete={false}
              />
            )
          )}
        </Card>
      );
    } else {
      return <div></div>;
    }
  }
}

import Box from "@/elements/Box";
import NumberInput from "@/elements/Input/NumberInput";
import {
  FormHelperText,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form";
import { RegistroDto, UpdateRegistroDto } from "@/api";
import { isNil, keyBy } from "lodash";

export type Registri_GFL_MONITORING_WT = {
  measHumTh1?: number;
  measHumTh2?: number;
};

export function registriToForm_GFL_MONITORING_WT(_registri: RegistroDto[]): {
  [r in keyof Registri_GFL_MONITORING_WT]: any;
} {
  const registri = keyBy(_registri, "registro");

  return {
    measHumTh1: isNil(registri["measHumTh1"])
      ? undefined
      : parseFloat(registri["measHumTh1"].value),
    measHumTh2: isNil(registri["measHumTh2"])
      ? undefined
      : parseFloat(registri["measHumTh2"].value),
  };
}

export function formToRegistri_GFL_MONITORING_WT(
  form: Registri_GFL_MONITORING_WT
): UpdateRegistroDto[] {
  const topicPrefix = "var";
  const _values: {
    [k in keyof Registri_GFL_MONITORING_WT]?: UpdateRegistroDto;
  } = {
    measHumTh1: isNil(form.measHumTh1)
      ? undefined
      : {
          registro: "measHumTh1",
          topicPrefix,
          value: form.measHumTh1,
        },
    measHumTh2: isNil(form.measHumTh2)
      ? undefined
      : {
          registro: "measHumTh2",
          topicPrefix,
          value: form.measHumTh2,
        },
  };
  return Object.values(_values);
}

export function EditorRegistri_GFL_MONITORING_WT({
  control,
  errors,
  register,
  readOnly = false,
}: {
  control: Control<Registri_GFL_MONITORING_WT>;
  errors: FieldErrors<Registri_GFL_MONITORING_WT>;
  register: UseFormRegister<Registri_GFL_MONITORING_WT>;
  readOnly?: boolean;
}) {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Card>
        <Box p={2} flex={1}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              variant="h5"
              color="text"
              fontWeight="medium"
              gutterBottom
            >
              Soglie
            </Typography>

            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Umidità soglia 1
                  </Typography>
                  <Controller
                    control={control}
                    name="measHumTh1"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          numericInputProps={{ decimalScale: 0 }}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />

              <Grid item sm={12} md={6}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    mr={2}
                  >
                    Umidità soglia 2
                  </Typography>
                  <Controller
                    control={control}
                    name="measHumTh2"
                    render={({ field, fieldState: { error } }) => (
                      <Stack direction="column" alignItems="end">
                        <NumberInput
                          {...field}
                          sx={{ maxWidth: 100 }}
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                          numericInputProps={{
                            decimalScale: 0,
                            min: 0,
                            max: 100,
                          }}
                        />
                        {error && (
                          <FormHelperText error>
                            {error?.message}
                          </FormHelperText>
                        )}
                      </Stack>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item sm={12} md={6} />
            </Grid>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

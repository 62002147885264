import {
  CentraleDto,
  useGetAzienda,
  useGetReparti,
  useGetStabilimenti,
} from "@/api";
import { useGetCentrali } from "@/api/centrali/centrali";
import CentraleCreateDialog from "@/components/CentraliPage/CentraleCreateDialog";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import {
  PosizioneFilter,
  usePosizioneFilter,
} from "@/hooks/usePosizioneFilter";
import { TablePagination } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AziendaPicker from "../AziendaPicker";
import CentraleCard from "../CentraleCard";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import RepartoPicker from "../RepartoPicker";
import StabilimentoPicker from "../StabilimentoPicker";

export function usePosizioneFilterWithNavigation(
  baseUrl: string,
  options?: { filterByCentrale?: boolean }
) {
  const filterByCentrale = options?.filterByCentrale ?? false;

  const { idAzienda, idStabilimento, idReparto, idCentrale } = useParams();
  const [filters, changeFilter, setFilters] = usePosizioneFilter();

  // url -> filter
  const justMountedRef = useRef<boolean>(true);

  const azienda = useGetAzienda(parseInt(idAzienda ?? "0"), {
    query: { enabled: justMountedRef.current && !!idAzienda },
  });
  const stabilimenti = useGetStabilimenti(
    { aziendaId: parseInt(idAzienda ?? "0") },
    {
      query: { enabled: justMountedRef.current && !!idStabilimento },
    }
  );
  const reparti = useGetReparti(
    {
      stabilimentoId: parseInt(idStabilimento ?? "0"),
    },
    {
      query: { enabled: justMountedRef.current && !!idReparto },
    }
  );
  const centrali = useGetCentrali(
    {
      repartoId: parseInt(idReparto ?? "0"),
    },
    {
      query: {
        enabled: filterByCentrale && justMountedRef.current && !!idCentrale,
      },
    }
  );

  useEffect(() => {
    if (!justMountedRef.current) {
      return;
    } else if (!idAzienda) {
      justMountedRef.current = false;
      return;
    }

    const loadingCompleted =
      (!idAzienda || azienda.data?.data) &&
      (!idStabilimento || stabilimenti.data?.data) &&
      (!idReparto || reparti.data?.data) &&
      (!filterByCentrale || !idCentrale || centrali.data?.data);

    if (loadingCompleted) {
      const filter: PosizioneFilter = {
        azienda: azienda.data?.data ?? null,
        stabilimento:
          stabilimenti.data?.data.find(
            (s) => s.id === parseInt(idStabilimento ?? "0")
          ) ?? null,
        reparto:
          reparti.data?.data.find((r) => r.id === parseInt(idReparto ?? "0")) ??
          null,
        centrale: filterByCentrale
          ? centrali.data?.data.find(
              (c) => c.id === parseInt(idCentrale ?? "0")
            ) ?? null
          : null,
      };
      setFilters(filter);
      justMountedRef.current = false;
    }
  }, [
    idAzienda,
    idStabilimento,
    idReparto,
    idCentrale,
    filterByCentrale,
    azienda.data,
    stabilimenti.data,
    reparti.data,
    centrali.data,
    justMountedRef,
    setFilters,
  ]);

  // filter -> url
  const navigate = useNavigate();
  useEffect(() => {
    const url = [
      baseUrl,
      filters.azienda?.id,
      filters.stabilimento?.id,
      filters.reparto?.id,
      filterByCentrale ? filters.centrale?.id : null,
    ]
      .filter((x) => !!x)
      .join("/");

    if (url !== baseUrl) {
      navigate(url);
    }
  }, [filters, navigate, baseUrl, filterByCentrale]);

  return [filters, changeFilter] as const;
}

function CentraliPage() {
  const { user } = useAuth();
  const { isLoading, error, data } = useGetCentrali();
  const [filters, changeFilter] =
    usePosizioneFilterWithNavigation("/lista_centrali");
  const [createCentraleDialogOpen, setCreateCentraleDialogOpen] =
    useState(false);

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const centrali = useMemo(() => {
    return (data?.data || []).filter((d) => {
      return matchFilter(d, filters);
    });
  }, [data?.data, filters]);

  const centraliCurrentPage = useMemo(() => {
    return centrali.slice(
      itemsPerPage * page,
      itemsPerPage * page + itemsPerPage
    );
  }, [centrali, page, itemsPerPage]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Centrali
        </Typography>

        <Box display="flex" gap={1} pt={2} flexWrap="wrap">
          {!user?.isEsterno && (
            <AziendaPicker
              value={filters.azienda}
              onChange={changeFilter("azienda", ["stabilimento", "reparto"])}
              placeholder="Azienda"
            />
          )}
          <StabilimentoPicker
            aziendaId={
              user?.isEsterno ? user?.azienda?.id : filters.azienda?.id
            }
            value={filters.stabilimento}
            onChange={changeFilter("stabilimento", ["reparto"])}
            placeholder="Stabilimento"
          />
          <RepartoPicker
            stabilimentoId={filters.stabilimento?.id}
            value={filters.reparto}
            onChange={changeFilter("reparto")}
            placeholder="Reparto"
          />
        </Box>
      </Box>

      {/* {hasPermission("CentraleCreate") && (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Box>
            <Button
              size="small"
              color="light"
              onClick={() => {
                setCreateCentraleDialogOpen(true);
              }}
            >
              Aggiungi centrale
            </Button>
          </Box>
        </Box>
      )} */}

      <Box
        mt={2}
        mb={0}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
      >
        {centraliCurrentPage.map((centrale) => {
          return (
            <CentraleCard
              key={centrale.id}
              centrale={centrale}
              showAzienda={!user?.isEsterno}
            />
          );
        })}
      </Box>

      {centrali.length === 0 && (
        <Box
          mt={2}
          mb={0}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap={2}
        >
          <Typography variant="body2">
            Nessun centrale
            {hasFilters(filters) ? " per i filtri impostati" : null}
          </Typography>
        </Box>
      )}

      <TablePagination
        component="div"
        page={page}
        count={centrali.length}
        onPageChange={(event, page) => {
          setPage(page);
        }}
        rowsPerPage={itemsPerPage}
        rowsPerPageOptions={[10, 20, 50]}
        onRowsPerPageChange={(e) => {
          setItemsPerPage(parseInt(e.target.value));
        }}
      />

      <CentraleCreateDialog
        azienda={filters.azienda ?? undefined}
        stabilimento={filters.stabilimento ?? undefined}
        reparto={filters.reparto ?? undefined}
        open={createCentraleDialogOpen}
        onClose={() => {
          setCreateCentraleDialogOpen(false);
        }}
      />
    </Box>
  );
}

export default CentraliPage;

function matchFilter(c: CentraleDto, filters: PosizioneFilter): unknown {
  if (filters.azienda && c?.azienda?.id !== filters.azienda.id) {
    return false;
  }
  if (filters.stabilimento && c.stabilimento?.id !== filters.stabilimento.id) {
    return false;
  }
  if (filters.reparto && c.reparto?.id !== filters.reparto.id) {
    return false;
  }
  return true;
}

function hasFilters(filters: PosizioneFilter): boolean {
  return Boolean(filters.azienda || filters.stabilimento || filters.reparto);
}

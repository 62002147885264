// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _rolePermissionsMap = {
  Admin: ["AccessAll"],
  Cliente: [
    "Home",
    "DispositiviRead",
    "DispositiviDatiStoriciRead",
    "CentraliRead",
    "AziendaRead",
  ],
  Tecnico: [
    "DispositiviRead",
    "DispositiviReadAll",
    "DispositivoCreate",
    "DispositivoDelete",
    "DispositiviUpdateTrasferimenti",
    "DispositiviReadManutenzioni",
    "DispositiviUpdateManutenzioni",
    "DispositiviDatiStoriciRead",
    "DispositiviDatiStoriciReadAll",
    "DispositiviAlertValuesEdit",
    "DispositiviAlertInternalUserOptionsEdit",
    "AziendeReadAll",
    "AziendaRead",
    "AziendaCreate",
    "AziendaEdit",
    "AziendaDelete",
    "StabilimentiReadAll",
    "StabilimentoCreate",
    "StabilimentoUpdate",
    "RepartiReadAll",
    "RepartoCreate",
    "CentraliRead",
    "CentraliReadAll",
    "CentraleCreate",
    "CentraleUpdate",
    "CentraleUpdateAlloggiamentiFiltro",
    "CentraleUpdateStoricoFiltri",
    "CentraleUpdateStoricoOlio",
    "CentraleUpdateBonifica",
    "CentraleUpdateImmagini",
    "CentraleDelete",
    "CentraleUpdateFiltrazioni",
    "TrasferimentiChange",
    "UsersInterniRead",
    "UsersEsterniRead",
    "UsersInterniChange",
    "UsersEsterniChange",
    "FileUpload",
  ],
  Laboratorio: [
    "CentraliRead",
    "CentraliReadAll",
    "CentraleUpdateAnalisi",
    "AziendeReadAll",
    "AziendeRead",
    "AziendaRead",
    "AziendaCreate",
    "AziendaEdit",
    "AziendaDelete",
    "StabilimentiReadAll",
    "StabilimentoCreate",
    "StabilimentoUpdate",
    "RepartiReadAll",
    "RepartoCreate",
    "DispositiviReadManutenzioni",
    "FileUpload",
    "AnalisiListaReadAll",
    "AnalisiListaRead",
    "AnalisiListaReadAll",
    "AnalisiListaRead",
    "FiltrazioniListaReadAll",
    "FiltrazioniListaRead",
    "BonificheListaReadAll",
    "BonificheListaRead",
    "CalendarioRead",
  ],
  Commerciale: [
    "CentraliRead",
    "AziendaRead",
    "AziendaCreate",
    "AziendaEdit",
    "AziendaDelete",
    "StabilimentiReadAll",
    "StabilimentoCreate",
    "StabilimentoUpdate",
    "RepartiReadAll",
    "RepartoCreate",
    "DispositiviReadManutenzioni",
    "AnalisiListaRead",
    "FiltrazioniListaRead",
    "BonificheListaRead",
    "CalendarioRead",
  ],
  "Area Manager": [
    "CentraliRead",
    "AziendeReadAll",
    "AziendaRead",
    "AziendaCreate",
    "AziendaEdit",
    "AziendaDelete",
    "StabilimentiReadAll",
    "StabilimentoCreate",
    "StabilimentoUpdate",
    "RepartiReadAll",
    "RepartoCreate",
    "FileUpload",
    "UsersInterniRead",
    "AnalisiListaRead",
    "FiltrazioniListaRead",
    "BonificheListaRead",
    "CalendarioRead",
  ],
} as const;

/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  DispositivoDto,
  DispositivoCreateDto,
  RegistroDto,
  DispositivoActionResultDto,
  DispositivoActionDto,
  UpdateRegistroResultDto,
  UpdateRegistroDto,
  RegistriMode,
  ProprietaMisurataValueDto,
  GetDispositivoHistoryDataParams,
  TrasferimentoUpdateDtoInt32EntityChange,
  ManutenzioneUpdateDtoInt32EntityChange,
} from ".././models";

export const getDispositivi = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DispositivoDto[]>> => {
  return axios.get(`/api/dispositivi`, options);
};

export const getGetDispositiviQueryKey = () => [`/api/dispositivi`] as const;

export const getGetDispositiviQueryOptions = <
  TData = Awaited<ReturnType<typeof getDispositivi>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDispositivi>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getDispositivi>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDispositiviQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDispositivi>>> = ({
    signal,
  }) => getDispositivi({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDispositiviQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDispositivi>>
>;
export type GetDispositiviQueryError = AxiosError<unknown>;

export const useGetDispositivi = <
  TData = Awaited<ReturnType<typeof getDispositivi>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDispositivi>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDispositiviQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const creaDispositivo = (
  dispositivoCreateDto: DispositivoCreateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DispositivoDto>> => {
  return axios.post(`/api/dispositivi`, dispositivoCreateDto, options);
};

export const getCreaDispositivoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof creaDispositivo>>,
    TError,
    { data: DispositivoCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof creaDispositivo>>,
  TError,
  { data: DispositivoCreateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof creaDispositivo>>,
    { data: DispositivoCreateDto }
  > = (props) => {
    const { data } = props ?? {};

    return creaDispositivo(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreaDispositivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof creaDispositivo>>
>;
export type CreaDispositivoMutationBody = DispositivoCreateDto;
export type CreaDispositivoMutationError = AxiosError<unknown>;

export const useCreaDispositivo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof creaDispositivo>>,
    TError,
    { data: DispositivoCreateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreaDispositivoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDispositivo = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DispositivoDto>> => {
  return axios.get(`/api/dispositivi/${id}`, options);
};

export const getGetDispositivoQueryKey = (id: number) =>
  [`/api/dispositivi/${id}`] as const;

export const getGetDispositivoQueryOptions = <
  TData = Awaited<ReturnType<typeof getDispositivo>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispositivo>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDispositivo>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDispositivoQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDispositivo>>> = ({
    signal,
  }) => getDispositivo(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetDispositivoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDispositivo>>
>;
export type GetDispositivoQueryError = AxiosError<unknown>;

export const useGetDispositivo = <
  TData = Awaited<ReturnType<typeof getDispositivo>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispositivo>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDispositivoQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const eliminaDispositivo = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/dispositivi/${id}`, options);
};

export const getEliminaDispositivoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eliminaDispositivo>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eliminaDispositivo>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eliminaDispositivo>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return eliminaDispositivo(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EliminaDispositivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof eliminaDispositivo>>
>;

export type EliminaDispositivoMutationError = AxiosError<unknown>;

export const useEliminaDispositivo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eliminaDispositivo>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getEliminaDispositivoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getRegistriDispositivo = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<RegistroDto[]>> => {
  return axios.get(`/api/dispositivi/${id}/registri`, options);
};

export const getGetRegistriDispositivoQueryKey = (id: number) =>
  [`/api/dispositivi/${id}/registri`] as const;

export const getGetRegistriDispositivoQueryOptions = <
  TData = Awaited<ReturnType<typeof getRegistriDispositivo>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRegistriDispositivo>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getRegistriDispositivo>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetRegistriDispositivoQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRegistriDispositivo>>
  > = ({ signal }) => getRegistriDispositivo(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetRegistriDispositivoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRegistriDispositivo>>
>;
export type GetRegistriDispositivoQueryError = AxiosError<unknown>;

export const useGetRegistriDispositivo = <
  TData = Awaited<ReturnType<typeof getRegistriDispositivo>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRegistriDispositivo>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRegistriDispositivoQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const actionDispositivo = (
  id: number,
  dispositivoActionDto: DispositivoActionDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DispositivoActionResultDto>> => {
  return axios.put(
    `/api/dispositivi/${id}/dispositivoAction`,
    dispositivoActionDto,
    options
  );
};

export const getActionDispositivoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof actionDispositivo>>,
    TError,
    { id: number; data: DispositivoActionDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof actionDispositivo>>,
  TError,
  { id: number; data: DispositivoActionDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof actionDispositivo>>,
    { id: number; data: DispositivoActionDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return actionDispositivo(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActionDispositivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof actionDispositivo>>
>;
export type ActionDispositivoMutationBody = DispositivoActionDto;
export type ActionDispositivoMutationError = AxiosError<unknown>;

export const useActionDispositivo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof actionDispositivo>>,
    TError,
    { id: number; data: DispositivoActionDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getActionDispositivoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateRegistriDispositivo = (
  id: number,
  mode: RegistriMode,
  updateRegistroDto: UpdateRegistroDto[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UpdateRegistroResultDto[]>> => {
  return axios.put(
    `/api/dispositivi/${id}/registri/${mode}`,
    updateRegistroDto,
    options
  );
};

export const getUpdateRegistriDispositivoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRegistriDispositivo>>,
    TError,
    { id: number; mode: RegistriMode; data: UpdateRegistroDto[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateRegistriDispositivo>>,
  TError,
  { id: number; mode: RegistriMode; data: UpdateRegistroDto[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateRegistriDispositivo>>,
    { id: number; mode: RegistriMode; data: UpdateRegistroDto[] }
  > = (props) => {
    const { id, mode, data } = props ?? {};

    return updateRegistriDispositivo(id, mode, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRegistriDispositivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRegistriDispositivo>>
>;
export type UpdateRegistriDispositivoMutationBody = UpdateRegistroDto[];
export type UpdateRegistriDispositivoMutationError = AxiosError<unknown>;

export const useUpdateRegistriDispositivo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRegistriDispositivo>>,
    TError,
    { id: number; mode: RegistriMode; data: UpdateRegistroDto[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateRegistriDispositivoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDispositivoHistoryData = (
  id: number,
  idMisura: number,
  params?: GetDispositivoHistoryDataParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProprietaMisurataValueDto[]>> => {
  return axios.get(`/api/dispositivi/${id}/misura/${idMisura}/history`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetDispositivoHistoryDataQueryKey = (
  id: number,
  idMisura: number,
  params?: GetDispositivoHistoryDataParams
) =>
  [
    `/api/dispositivi/${id}/misura/${idMisura}/history`,
    ...(params ? [params] : []),
  ] as const;

export const getGetDispositivoHistoryDataQueryOptions = <
  TData = Awaited<ReturnType<typeof getDispositivoHistoryData>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idMisura: number,
  params?: GetDispositivoHistoryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispositivoHistoryData>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDispositivoHistoryData>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDispositivoHistoryDataQueryKey(id, idMisura, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDispositivoHistoryData>>
  > = ({ signal }) =>
    getDispositivoHistoryData(id, idMisura, params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, enabled: !!(id && idMisura), ...queryOptions };
};

export type GetDispositivoHistoryDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDispositivoHistoryData>>
>;
export type GetDispositivoHistoryDataQueryError = AxiosError<unknown>;

export const useGetDispositivoHistoryData = <
  TData = Awaited<ReturnType<typeof getDispositivoHistoryData>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idMisura: number,
  params?: GetDispositivoHistoryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispositivoHistoryData>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDispositivoHistoryDataQueryOptions(
    id,
    idMisura,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateTrasferimentiDispositivo = (
  id: number,
  trasferimentoUpdateDtoInt32EntityChange: TrasferimentoUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.post(
    `/api/dispositivi/${id}/trasferimenti`,
    trasferimentoUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateTrasferimentiDispositivoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>,
    TError,
    { id: number; data: TrasferimentoUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>,
  TError,
  { id: number; data: TrasferimentoUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>,
    { id: number; data: TrasferimentoUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateTrasferimentiDispositivo(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTrasferimentiDispositivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>
>;
export type UpdateTrasferimentiDispositivoMutationBody =
  TrasferimentoUpdateDtoInt32EntityChange[];
export type UpdateTrasferimentiDispositivoMutationError = AxiosError<unknown>;

export const useUpdateTrasferimentiDispositivo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>,
    TError,
    { id: number; data: TrasferimentoUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getUpdateTrasferimentiDispositivoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getManutenzioni = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/dispositivi/${id}/manutenzioni`, options);
};

export const getGetManutenzioniQueryKey = (id: number) =>
  [`/api/dispositivi/${id}/manutenzioni`] as const;

export const getGetManutenzioniQueryOptions = <
  TData = Awaited<ReturnType<typeof getManutenzioni>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getManutenzioni>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getManutenzioni>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManutenzioniQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManutenzioni>>> = ({
    signal,
  }) => getManutenzioni(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetManutenzioniQueryResult = NonNullable<
  Awaited<ReturnType<typeof getManutenzioni>>
>;
export type GetManutenzioniQueryError = AxiosError<unknown>;

export const useGetManutenzioni = <
  TData = Awaited<ReturnType<typeof getManutenzioni>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getManutenzioni>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetManutenzioniQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateManutenzioni = (
  id: number,
  manutenzioneUpdateDtoInt32EntityChange: ManutenzioneUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/dispositivi/${id}/manutenzioni`,
    manutenzioneUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateManutenzioniMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateManutenzioni>>,
    TError,
    { id: number; data: ManutenzioneUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateManutenzioni>>,
  TError,
  { id: number; data: ManutenzioneUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateManutenzioni>>,
    { id: number; data: ManutenzioneUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateManutenzioni(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateManutenzioniMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateManutenzioni>>
>;
export type UpdateManutenzioniMutationBody =
  ManutenzioneUpdateDtoInt32EntityChange[];
export type UpdateManutenzioniMutationError = AxiosError<unknown>;

export const useUpdateManutenzioni = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateManutenzioni>>,
    TError,
    { id: number; data: ManutenzioneUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateManutenzioniMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadRapportinoManutenzione = (
  id: number,
  idManutenzione: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(
    `/api/dispositivi/${id}/manutenzioni/${idManutenzione}/rapportino`,
    options
  );
};

export const getDownloadRapportinoManutenzioneQueryKey = (
  id: number,
  idManutenzione: number
) =>
  [`/api/dispositivi/${id}/manutenzioni/${idManutenzione}/rapportino`] as const;

export const getDownloadRapportinoManutenzioneQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadRapportinoManutenzione>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idManutenzione: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadRapportinoManutenzione>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof downloadRapportinoManutenzione>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadRapportinoManutenzioneQueryKey(id, idManutenzione);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadRapportinoManutenzione>>
  > = ({ signal }) =>
    downloadRapportinoManutenzione(id, idManutenzione, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(id && idManutenzione),
    ...queryOptions,
  };
};

export type DownloadRapportinoManutenzioneQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadRapportinoManutenzione>>
>;
export type DownloadRapportinoManutenzioneQueryError = AxiosError<unknown>;

export const useDownloadRapportinoManutenzione = <
  TData = Awaited<ReturnType<typeof downloadRapportinoManutenzione>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idManutenzione: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadRapportinoManutenzione>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadRapportinoManutenzioneQueryOptions(
    id,
    idManutenzione,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const __$$useGetDispositivi = () =>
  //@ts-ignore
  useGetDispositivi(...([] as any[]));

export const __$$useCreaDispositivo = () =>
  //@ts-ignore
  useCreaDispositivo(...([] as any[]));

export const __$$useGetDispositivo = () =>
  //@ts-ignore
  useGetDispositivo(...([] as any[]));

export const __$$useEliminaDispositivo = () =>
  //@ts-ignore
  useEliminaDispositivo(...([] as any[]));

export const __$$useGetRegistriDispositivo = () =>
  //@ts-ignore
  useGetRegistriDispositivo(...([] as any[]));

export const __$$useActionDispositivo = () =>
  //@ts-ignore
  useActionDispositivo(...([] as any[]));

export const __$$useUpdateRegistriDispositivo = () =>
  //@ts-ignore
  useUpdateRegistriDispositivo(...([] as any[]));

export const __$$useGetDispositivoHistoryData = () =>
  //@ts-ignore
  useGetDispositivoHistoryData(...([] as any[]));

export const __$$useUpdateTrasferimentiDispositivo = () =>
  //@ts-ignore
  useUpdateTrasferimentiDispositivo(...([] as any[]));

export const __$$useGetManutenzioni = () =>
  //@ts-ignore
  useGetManutenzioni(...([] as any[]));

export const __$$useUpdateManutenzioni = () =>
  //@ts-ignore
  useUpdateManutenzioni(...([] as any[]));

export const __$$useDownloadRapportinoManutenzione = () =>
  //@ts-ignore
  useDownloadRapportinoManutenzione(...([] as any[]));

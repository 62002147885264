/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  CentraleDto,
  GetCentraliParams,
  CentraleCreationDto,
  CentraleUpdateDto,
  AlloggiamentiFiltroUpdateDtoInt32EntityChange,
  FiltroUpdateDtoInt32EntityChange,
  OlioUpdateDtoInt32EntityChange,
  ReminderUpdateDtoInt32EntityChange,
  BonificaUpdateDtoInt32EntityChange,
  AnalisiUpdateDtoInt32EntityChange,
  FiltrazioneUpdateDtoInt32EntityChange,
  ImmaginiUpdateDtoInt32EntityChange,
} from ".././models";

export const getCentrali = (
  params?: GetCentraliParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CentraleDto[]>> => {
  return axios.get(`/api/centrali`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetCentraliQueryKey = (params?: GetCentraliParams) =>
  [`/api/centrali`, ...(params ? [params] : [])] as const;

export const getGetCentraliQueryOptions = <
  TData = Awaited<ReturnType<typeof getCentrali>>,
  TError = AxiosError<unknown>
>(
  params?: GetCentraliParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCentrali>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getCentrali>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCentraliQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCentrali>>> = ({
    signal,
  }) => getCentrali(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCentraliQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCentrali>>
>;
export type GetCentraliQueryError = AxiosError<unknown>;

export const useGetCentrali = <
  TData = Awaited<ReturnType<typeof getCentrali>>,
  TError = AxiosError<unknown>
>(
  params?: GetCentraliParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCentrali>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCentraliQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createCentrali = (
  centraleCreationDto: CentraleCreationDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CentraleDto>> => {
  return axios.post(`/api/centrali`, centraleCreationDto, options);
};

export const getCreateCentraliMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCentrali>>,
    TError,
    { data: CentraleCreationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCentrali>>,
  TError,
  { data: CentraleCreationDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCentrali>>,
    { data: CentraleCreationDto }
  > = (props) => {
    const { data } = props ?? {};

    return createCentrali(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCentraliMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCentrali>>
>;
export type CreateCentraliMutationBody = CentraleCreationDto;
export type CreateCentraliMutationError = AxiosError<unknown>;

export const useCreateCentrali = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCentrali>>,
    TError,
    { data: CentraleCreationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreateCentraliMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateCentrale = (
  id: number,
  centraleUpdateDto: CentraleUpdateDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/centrali/${id}`, centraleUpdateDto, options);
};

export const getUpdateCentraleMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCentrale>>,
    TError,
    { id: number; data: CentraleUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCentrale>>,
  TError,
  { id: number; data: CentraleUpdateDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCentrale>>,
    { id: number; data: CentraleUpdateDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateCentrale(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCentraleMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCentrale>>
>;
export type UpdateCentraleMutationBody = CentraleUpdateDto;
export type UpdateCentraleMutationError = AxiosError<unknown>;

export const useUpdateCentrale = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCentrale>>,
    TError,
    { id: number; data: CentraleUpdateDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateCentraleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCentrale = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CentraleDto>> => {
  return axios.get(`/api/centrali/${id}`, options);
};

export const getGetCentraleQueryKey = (id: number) =>
  [`/api/centrali/${id}`] as const;

export const getGetCentraleQueryOptions = <
  TData = Awaited<ReturnType<typeof getCentrale>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCentrale>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getCentrale>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCentraleQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCentrale>>> = ({
    signal,
  }) => getCentrale(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetCentraleQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCentrale>>
>;
export type GetCentraleQueryError = AxiosError<unknown>;

export const useGetCentrale = <
  TData = Awaited<ReturnType<typeof getCentrale>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCentrale>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCentraleQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteCentrale = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.delete(`/api/centrali/${id}`, options);
};

export const getDeleteCentraleMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCentrale>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCentrale>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCentrale>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deleteCentrale(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCentraleMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCentrale>>
>;

export type DeleteCentraleMutationError = AxiosError<unknown>;

export const useDeleteCentrale = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCentrale>>,
    TError,
    { id: number },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getDeleteCentraleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCentraleByQRCode = (
  id: string,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<CentraleDto>> => {
  return axios.get(`/api/centrali/qrcode/${id}`, options);
};

export const getGetCentraleByQRCodeQueryKey = (id: string) =>
  [`/api/centrali/qrcode/${id}`] as const;

export const getGetCentraleByQRCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof getCentraleByQRCode>>,
  TError = AxiosError<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCentraleByQRCode>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getCentraleByQRCode>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCentraleByQRCodeQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCentraleByQRCode>>
  > = ({ signal }) => getCentraleByQRCode(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetCentraleByQRCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCentraleByQRCode>>
>;
export type GetCentraleByQRCodeQueryError = AxiosError<unknown>;

export const useGetCentraleByQRCode = <
  TData = Awaited<ReturnType<typeof getCentraleByQRCode>>,
  TError = AxiosError<unknown>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCentraleByQRCode>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCentraleByQRCodeQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateAlloggiamentiFiltro = (
  id: number,
  alloggiamentiFiltroUpdateDtoInt32EntityChange: AlloggiamentiFiltroUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/centrali/${id}/alloggiamentiFiltro`,
    alloggiamentiFiltroUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateAlloggiamentiFiltroMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAlloggiamentiFiltro>>,
    TError,
    { id: number; data: AlloggiamentiFiltroUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAlloggiamentiFiltro>>,
  TError,
  { id: number; data: AlloggiamentiFiltroUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAlloggiamentiFiltro>>,
    { id: number; data: AlloggiamentiFiltroUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateAlloggiamentiFiltro(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAlloggiamentiFiltroMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAlloggiamentiFiltro>>
>;
export type UpdateAlloggiamentiFiltroMutationBody =
  AlloggiamentiFiltroUpdateDtoInt32EntityChange[];
export type UpdateAlloggiamentiFiltroMutationError = AxiosError<unknown>;

export const useUpdateAlloggiamentiFiltro = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAlloggiamentiFiltro>>,
    TError,
    { id: number; data: AlloggiamentiFiltroUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateAlloggiamentiFiltroMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateStoricoFiltri = (
  id: number,
  alloggiamentoFiltroId: number,
  filtroUpdateDtoInt32EntityChange: FiltroUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/centrali/${id}/alloggiamentiFiltro/${alloggiamentoFiltroId}/storicoFiltri`,
    filtroUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateStoricoFiltriMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStoricoFiltri>>,
    TError,
    {
      id: number;
      alloggiamentoFiltroId: number;
      data: FiltroUpdateDtoInt32EntityChange[];
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStoricoFiltri>>,
  TError,
  {
    id: number;
    alloggiamentoFiltroId: number;
    data: FiltroUpdateDtoInt32EntityChange[];
  },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStoricoFiltri>>,
    {
      id: number;
      alloggiamentoFiltroId: number;
      data: FiltroUpdateDtoInt32EntityChange[];
    }
  > = (props) => {
    const { id, alloggiamentoFiltroId, data } = props ?? {};

    return updateStoricoFiltri(id, alloggiamentoFiltroId, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateStoricoFiltriMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStoricoFiltri>>
>;
export type UpdateStoricoFiltriMutationBody =
  FiltroUpdateDtoInt32EntityChange[];
export type UpdateStoricoFiltriMutationError = AxiosError<unknown>;

export const useUpdateStoricoFiltri = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStoricoFiltri>>,
    TError,
    {
      id: number;
      alloggiamentoFiltroId: number;
      data: FiltroUpdateDtoInt32EntityChange[];
    },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateStoricoFiltriMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateStoricoOlio = (
  id: number,
  olioUpdateDtoInt32EntityChange: OlioUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/centrali/${id}/storicoOlio`,
    olioUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateStoricoOlioMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStoricoOlio>>,
    TError,
    { id: number; data: OlioUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStoricoOlio>>,
  TError,
  { id: number; data: OlioUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStoricoOlio>>,
    { id: number; data: OlioUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateStoricoOlio(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateStoricoOlioMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStoricoOlio>>
>;
export type UpdateStoricoOlioMutationBody = OlioUpdateDtoInt32EntityChange[];
export type UpdateStoricoOlioMutationError = AxiosError<unknown>;

export const useUpdateStoricoOlio = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStoricoOlio>>,
    TError,
    { id: number; data: OlioUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateStoricoOlioMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateReminders = (
  id: number,
  reminderUpdateDtoInt32EntityChange: ReminderUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/centrali/${id}/reminders`,
    reminderUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateRemindersMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReminders>>,
    TError,
    { id: number; data: ReminderUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReminders>>,
  TError,
  { id: number; data: ReminderUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReminders>>,
    { id: number; data: ReminderUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateReminders(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRemindersMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReminders>>
>;
export type UpdateRemindersMutationBody = ReminderUpdateDtoInt32EntityChange[];
export type UpdateRemindersMutationError = AxiosError<unknown>;

export const useUpdateReminders = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReminders>>,
    TError,
    { id: number; data: ReminderUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateRemindersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateBonifiche = (
  id: number,
  bonificaUpdateDtoInt32EntityChange: BonificaUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/centrali/${id}/bonifiche`,
    bonificaUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateBonificheMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBonifiche>>,
    TError,
    { id: number; data: BonificaUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateBonifiche>>,
  TError,
  { id: number; data: BonificaUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBonifiche>>,
    { id: number; data: BonificaUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateBonifiche(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBonificheMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateBonifiche>>
>;
export type UpdateBonificheMutationBody = BonificaUpdateDtoInt32EntityChange[];
export type UpdateBonificheMutationError = AxiosError<unknown>;

export const useUpdateBonifiche = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBonifiche>>,
    TError,
    { id: number; data: BonificaUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateBonificheMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateAnalisi = (
  id: number,
  analisiUpdateDtoInt32EntityChange: AnalisiUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/centrali/${id}/analisi`,
    analisiUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateAnalisiMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAnalisi>>,
    TError,
    { id: number; data: AnalisiUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateAnalisi>>,
  TError,
  { id: number; data: AnalisiUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAnalisi>>,
    { id: number; data: AnalisiUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateAnalisi(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAnalisiMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAnalisi>>
>;
export type UpdateAnalisiMutationBody = AnalisiUpdateDtoInt32EntityChange[];
export type UpdateAnalisiMutationError = AxiosError<unknown>;

export const useUpdateAnalisi = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAnalisi>>,
    TError,
    { id: number; data: AnalisiUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateAnalisiMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadCentraleImmagine = (
  id: number,
  idImmagineCentrale: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(
    `/api/centrali/${id}/immagini/${idImmagineCentrale}`,
    options
  );
};

export const getDownloadCentraleImmagineQueryKey = (
  id: number,
  idImmagineCentrale: number
) => [`/api/centrali/${id}/immagini/${idImmagineCentrale}`] as const;

export const getDownloadCentraleImmagineQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadCentraleImmagine>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idImmagineCentrale: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadCentraleImmagine>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof downloadCentraleImmagine>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadCentraleImmagineQueryKey(id, idImmagineCentrale);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadCentraleImmagine>>
  > = ({ signal }) =>
    downloadCentraleImmagine(id, idImmagineCentrale, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(id && idImmagineCentrale),
    ...queryOptions,
  };
};

export type DownloadCentraleImmagineQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadCentraleImmagine>>
>;
export type DownloadCentraleImmagineQueryError = AxiosError<unknown>;

export const useDownloadCentraleImmagine = <
  TData = Awaited<ReturnType<typeof downloadCentraleImmagine>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idImmagineCentrale: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadCentraleImmagine>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadCentraleImmagineQueryOptions(
    id,
    idImmagineCentrale,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const downloadBonificaImmagine = (
  id: number,
  idBonifica: number,
  idImmagineBonifica: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(
    `/api/centrali/${id}/bonifiche/${idBonifica}/immagine/${idImmagineBonifica}`,
    options
  );
};

export const getDownloadBonificaImmagineQueryKey = (
  id: number,
  idBonifica: number,
  idImmagineBonifica: number
) =>
  [
    `/api/centrali/${id}/bonifiche/${idBonifica}/immagine/${idImmagineBonifica}`,
  ] as const;

export const getDownloadBonificaImmagineQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadBonificaImmagine>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idBonifica: number,
  idImmagineBonifica: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadBonificaImmagine>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof downloadBonificaImmagine>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadBonificaImmagineQueryKey(id, idBonifica, idImmagineBonifica);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadBonificaImmagine>>
  > = ({ signal }) =>
    downloadBonificaImmagine(id, idBonifica, idImmagineBonifica, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(id && idBonifica && idImmagineBonifica),
    ...queryOptions,
  };
};

export type DownloadBonificaImmagineQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadBonificaImmagine>>
>;
export type DownloadBonificaImmagineQueryError = AxiosError<unknown>;

export const useDownloadBonificaImmagine = <
  TData = Awaited<ReturnType<typeof downloadBonificaImmagine>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idBonifica: number,
  idImmagineBonifica: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadBonificaImmagine>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadBonificaImmagineQueryOptions(
    id,
    idBonifica,
    idImmagineBonifica,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const downloadRapportinoBonifica = (
  id: number,
  idBonifica: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(
    `/api/centrali/${id}/bonifiche/${idBonifica}/rapportino`,
    options
  );
};

export const getDownloadRapportinoBonificaQueryKey = (
  id: number,
  idBonifica: number
) => [`/api/centrali/${id}/bonifiche/${idBonifica}/rapportino`] as const;

export const getDownloadRapportinoBonificaQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadRapportinoBonifica>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idBonifica: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadRapportinoBonifica>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof downloadRapportinoBonifica>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadRapportinoBonificaQueryKey(id, idBonifica);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadRapportinoBonifica>>
  > = ({ signal }) =>
    downloadRapportinoBonifica(id, idBonifica, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(id && idBonifica), ...queryOptions };
};

export type DownloadRapportinoBonificaQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadRapportinoBonifica>>
>;
export type DownloadRapportinoBonificaQueryError = AxiosError<unknown>;

export const useDownloadRapportinoBonifica = <
  TData = Awaited<ReturnType<typeof downloadRapportinoBonifica>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idBonifica: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadRapportinoBonifica>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadRapportinoBonificaQueryOptions(
    id,
    idBonifica,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateFiltrazioni = (
  id: number,
  filtrazioneUpdateDtoInt32EntityChange: FiltrazioneUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/centrali/${id}/filtrazioni`,
    filtrazioneUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateFiltrazioniMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFiltrazioni>>,
    TError,
    { id: number; data: FiltrazioneUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFiltrazioni>>,
  TError,
  { id: number; data: FiltrazioneUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFiltrazioni>>,
    { id: number; data: FiltrazioneUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateFiltrazioni(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFiltrazioniMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFiltrazioni>>
>;
export type UpdateFiltrazioniMutationBody =
  FiltrazioneUpdateDtoInt32EntityChange[];
export type UpdateFiltrazioniMutationError = AxiosError<unknown>;

export const useUpdateFiltrazioni = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFiltrazioni>>,
    TError,
    { id: number; data: FiltrazioneUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateFiltrazioniMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadRapportinoFiltrazione = (
  id: number,
  idFiltrazione: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(
    `/api/centrali/${id}/filtrazioni/${idFiltrazione}/rapportino`,
    options
  );
};

export const getDownloadRapportinoFiltrazioneQueryKey = (
  id: number,
  idFiltrazione: number
) => [`/api/centrali/${id}/filtrazioni/${idFiltrazione}/rapportino`] as const;

export const getDownloadRapportinoFiltrazioneQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadRapportinoFiltrazione>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idFiltrazione: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadRapportinoFiltrazione>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof downloadRapportinoFiltrazione>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadRapportinoFiltrazioneQueryKey(id, idFiltrazione);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadRapportinoFiltrazione>>
  > = ({ signal }) =>
    downloadRapportinoFiltrazione(id, idFiltrazione, {
      signal,
      ...axiosOptions,
    });

  return {
    queryKey,
    queryFn,
    enabled: !!(id && idFiltrazione),
    ...queryOptions,
  };
};

export type DownloadRapportinoFiltrazioneQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadRapportinoFiltrazione>>
>;
export type DownloadRapportinoFiltrazioneQueryError = AxiosError<unknown>;

export const useDownloadRapportinoFiltrazione = <
  TData = Awaited<ReturnType<typeof downloadRapportinoFiltrazione>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idFiltrazione: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadRapportinoFiltrazione>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadRapportinoFiltrazioneQueryOptions(
    id,
    idFiltrazione,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const downloadPdfAnalisi = (
  id: number,
  idAnalisi: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.get(`/api/centrali/${id}/analisi/${idAnalisi}/pdf`, options);
};

export const getDownloadPdfAnalisiQueryKey = (id: number, idAnalisi: number) =>
  [`/api/centrali/${id}/analisi/${idAnalisi}/pdf`] as const;

export const getDownloadPdfAnalisiQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadPdfAnalisi>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idAnalisi: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadPdfAnalisi>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof downloadPdfAnalisi>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDownloadPdfAnalisiQueryKey(id, idAnalisi);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadPdfAnalisi>>
  > = ({ signal }) =>
    downloadPdfAnalisi(id, idAnalisi, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!(id && idAnalisi), ...queryOptions };
};

export type DownloadPdfAnalisiQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadPdfAnalisi>>
>;
export type DownloadPdfAnalisiQueryError = AxiosError<unknown>;

export const useDownloadPdfAnalisi = <
  TData = Awaited<ReturnType<typeof downloadPdfAnalisi>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idAnalisi: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadPdfAnalisi>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadPdfAnalisiQueryOptions(
    id,
    idAnalisi,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateImmagini = (
  id: number,
  immaginiUpdateDtoInt32EntityChange: ImmaginiUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(
    `/api/centrali/${id}/immagini`,
    immaginiUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateImmaginiMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateImmagini>>,
    TError,
    { id: number; data: ImmaginiUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateImmagini>>,
  TError,
  { id: number; data: ImmaginiUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateImmagini>>,
    { id: number; data: ImmaginiUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateImmagini(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateImmaginiMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateImmagini>>
>;
export type UpdateImmaginiMutationBody = ImmaginiUpdateDtoInt32EntityChange[];
export type UpdateImmaginiMutationError = AxiosError<unknown>;

export const useUpdateImmagini = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateImmagini>>,
    TError,
    { id: number; data: ImmaginiUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateImmaginiMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetCentrali = () =>
  //@ts-ignore
  useGetCentrali(...([] as any[]));

export const __$$useCreateCentrali = () =>
  //@ts-ignore
  useCreateCentrali(...([] as any[]));

export const __$$useUpdateCentrale = () =>
  //@ts-ignore
  useUpdateCentrale(...([] as any[]));

export const __$$useGetCentrale = () =>
  //@ts-ignore
  useGetCentrale(...([] as any[]));

export const __$$useDeleteCentrale = () =>
  //@ts-ignore
  useDeleteCentrale(...([] as any[]));

export const __$$useGetCentraleByQRCode = () =>
  //@ts-ignore
  useGetCentraleByQRCode(...([] as any[]));

export const __$$useUpdateAlloggiamentiFiltro = () =>
  //@ts-ignore
  useUpdateAlloggiamentiFiltro(...([] as any[]));

export const __$$useUpdateStoricoFiltri = () =>
  //@ts-ignore
  useUpdateStoricoFiltri(...([] as any[]));

export const __$$useUpdateStoricoOlio = () =>
  //@ts-ignore
  useUpdateStoricoOlio(...([] as any[]));

export const __$$useUpdateReminders = () =>
  //@ts-ignore
  useUpdateReminders(...([] as any[]));

export const __$$useUpdateBonifiche = () =>
  //@ts-ignore
  useUpdateBonifiche(...([] as any[]));

export const __$$useUpdateAnalisi = () =>
  //@ts-ignore
  useUpdateAnalisi(...([] as any[]));

export const __$$useDownloadCentraleImmagine = () =>
  //@ts-ignore
  useDownloadCentraleImmagine(...([] as any[]));

export const __$$useDownloadBonificaImmagine = () =>
  //@ts-ignore
  useDownloadBonificaImmagine(...([] as any[]));

export const __$$useDownloadRapportinoBonifica = () =>
  //@ts-ignore
  useDownloadRapportinoBonifica(...([] as any[]));

export const __$$useUpdateFiltrazioni = () =>
  //@ts-ignore
  useUpdateFiltrazioni(...([] as any[]));

export const __$$useDownloadRapportinoFiltrazione = () =>
  //@ts-ignore
  useDownloadRapportinoFiltrazione(...([] as any[]));

export const __$$useDownloadPdfAnalisi = () =>
  //@ts-ignore
  useDownloadPdfAnalisi(...([] as any[]));

export const __$$useUpdateImmagini = () =>
  //@ts-ignore
  useUpdateImmagini(...([] as any[]));
